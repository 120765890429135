export default {
  root: {
    borderRadius: 20,
    '&&:hover': {
      backgroundColor: 'transparent'
    },
  },
  text: {
    padding: '2px 16px',
  },
  label: {
    textTransform: 'none'
  }
};
