import React, { Suspense, useState, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import TopBar from './TopBar';
import FooterBar from './FooterBar';
import Newsletter from './Newsletter';

// import { useMediaQuery } from 'react-responsive'

// import NavBar from './NavBar';
// import TopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  root: {},
  topBar: {},
  newsletter: {
    backgroundColor: theme.palette.background.default
  },
  footerBar: {},
  container: {},
  content: {}
}));

const MasterWithNav = ({ route }) => {
  const classes = useStyles();

  useEffect(() => {
    let loaded = true;

    // const getMenuWidth = async => {
    //   if (isBigScreen) {
    //     console.log('is big screen!');
    //     setMenuWidth(menuBigScreen);
    //   } else if (isMediumScreen) {
    //     console.log('is medium screen!');
    //     setMenuWidth(menuMediumScreen);
    //   } else if (isTinyScreen) {
    //     console.log('is tiny screen!');
    //     setMenuWidth(menuTinyScreen)
    //   } else {
    //     console.log('is another screen!');
    //     setMenuWidth(256);
    //   }
    // };

    // getMenuWidth();

    return () => {
      loaded = false;
    };
  }, []);

  return (
    <div className={classes.root}>
      <TopBar className={classes.topBar} />

      <div className={classes.container}>
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>

      <Newsletter className={classes.newsletter} />
      <FooterBar className={classes.footerBar} />
    </div>
  );
};

MasterWithNav.propTypes = {
  route: PropTypes.object
};

export default MasterWithNav;
