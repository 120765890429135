/* eslint-disable no-undef */
import axios from 'axios';
import Cookies from 'js-cookie';

const apiAuth = axios.create({
  baseURL: process.env.REACT_APP_AUTHENTICATION_BASEURL
});

export const TOKEN_KEY = 'mks_tkn';
export const isAuthenticated = () => Cookies.get(TOKEN_KEY) !== null;
export const getToken = () => Cookies.get(TOKEN_KEY);

export const getTokenClient = async () => {
  let data = new FormData();
  data.append('grant_type', 'client_credentials');
  data.append(
    'client_id',
    process.env.REACT_APP_AUTHENTICATION_CREATE_CLIENT_ID
  );
  data.append(
    'client_secret',
    process.env.REACT_APP_AUTHENTICATION_CREATE_CLIENT_SECRET
  );

  try {
    const response = await apiAuth.post('/connect/token', data);
    if (response.data === null) {
      throw new Error(response);
    }

    return response.data.access_token;
  } catch (ex) {
    return ex;
  }
};

export const authLogin = async (username, password) => {
  let data = new FormData();
  data.append('grant_type', 'password');
  data.append('client_id', process.env.REACT_APP_AUTHENTICATION_CLIENT_ID);
  data.append(
    'client_secret',
    process.env.REACT_APP_AUTHENTICATION_CLIENT_SECRET
  );
  data.append('username', username);
  data.append('password', password);

  try {
    const response = await apiAuth.post('/connect/token', data);
    if (response.data === null) {
      throw new Error(response);
    }

    Cookies.set(TOKEN_KEY, response.data.access_token, {
      expires: response.data.expires_in / 86400
      //,domain: process.env.REACT_APP_MOKSHA_ADMIN_DOMAIN
    });
    return true;
  } catch (ex) {
    return ex;
  }
};
