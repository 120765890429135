import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';

const SimpleDialog = ({
  isShowing,
  hide,
  open,
  title,
  description,
  hideTextButton
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Dialog
            aria-labelledby="responsive-dialog-title"
            fullScreen={fullScreen}
            onClose={hide}
            open={open}>
            <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus color="primary" onClick={hide}>
                {hideTextButton}
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>,
        document.body
      )
    : null;
};
export default SimpleDialog;
