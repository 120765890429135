
import api from './api';

export class NewsletterService {
    static newsletterSubscribe = async (userCredentials) => {
        try {
            return await api.post('/api/v1/newsletter', userCredentials);
        } catch (ex) {
            return ex;
        }
    };
}

export default NewsletterService;
