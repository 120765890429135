import palette from './palette';

const typography = theme => ({
  fontFamily: 'Montserrat SemiBold, Arial',
  h1: {
    fontSize: "37.4px",
    lineHeight: "40.8px",
    color: palette.text.primary,
    fontFamily: 'Montserrat Bold',
    fontWeight: 500,
    letterSpacing: '-0.24px',
  },
  h2: {
    fontSize: "28px",
    lineHeight: "30px",
    color: palette.primary.main,
    fontFamily: 'Montserrat Bold',
    fontWeight: 500,
    letterSpacing: '-0.24px',
  },
  h3: {
    fontSize: "17px",
    lineHeight: "21.76px",
    color: palette.text.primary,
    fontFamily: 'Montserrat SemiBold',
    fontWeight: 500,
    letterSpacing: '-0.05px',
  },
  h4: {
    fontSize: "14px",
    lineHeight: "20px",
    color: palette.text.secondary,
    fontFamily: 'Montserrat Medium',
    fontWeight: 500,
    letterSpacing: '-0.06px',

  },
  h5: {
    fontSize: "12px",
    lineHeight: "18px",
    color: palette.text.secondary,
    fontFamily: 'Montserrat Medium',
    fontWeight: 500,
    letterSpacing: '-0.05px',
  },
  h6: {
    color: palette.text.primary,
    fontFamily: 'Montserrat Bold',
    fontWeight: 500,
    fontSize: '26px',
    letterSpacing: '-0.05px',
    lineHeight: '44px'
  },
  subtitle1: {
    color: palette.text.primary,
    fontFamily: 'Montserrat Bold',
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '25px',
    marginTop: '17px'
  },
  subtitle2: {
    fontSize: "14px",
    lineHeight: "26px",
    color: palette.text.primary,
    fontFamily: 'Montserrat Medium',
    fontWeight: 400,
    letterSpacing: '-0.05px',
  },
  body1: {
    fontSize: "13.6px",
    lineHeight: "20.4px",
    color: palette.text.secondary,
    fontFamily: 'Montserrat Medium',
    fontWeight: 500,
    letterSpacing: '-0.06px',
  },
  body2: {
    fontSize: '18px',
    lineHeight: '26px',
    color: palette.text.secondary,
    letterSpacing: '-0.04px',
    fontFamily: 'Montserrat Medium',
  },
  button: {
    color: palette.text.primary,
    fontSize: '14px'
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '8px',
    fontFamily: 'Montserrat Medium',
    letterSpacing: '0.33px',
    lineHeight: '14px'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase'
  },
});

export default typography;