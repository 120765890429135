/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import MasterWithNavLayout from './layouts/MasterWithNav';

const routes = [
  {
    route: '*',
    component: MasterWithNavLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(() => import('./views/Home'))
      },
      {
        path: '/signup',
        exact: true,
        component: lazy(() => import('./views/Signup'))
      },
      {
        path: '/policies',
        exact: true,
        component: lazy(() => import('./views/Policies'))
      }
    ]
  }
];

export default routes;
