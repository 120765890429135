import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import useRouter from '../../utils/useRouter';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    backgroundColor: theme.palette.white,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '98px',
      marginTop: '98px'
    }
  },
  container: {
    maxWidth: '95%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1112px'
    }
  },
  moksha: {
    paddingBottom: '17px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '28px',
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '58px'
    }
  },
  social: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '34px'
    },
    display: 'flex',
    alignItems: 'flex-start'
  },
  facebookBox: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '20px'
    }
  },
  instagramBox: {
    paddingLeft: '12px'
  },
  address: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '34px'
    }
  },
  contact: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '58px'
    }
  }
}));

const FooterBar = ({}) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Typography component="div" className={classes.content}>
          <Grid container spacing={0}>
            <Grid item sm={12} xs={12} className={classes.moksha}>
              <Box>
                <img alt="Logo" src="/images/moksha-gray.png" width="113" />
              </Box>
            </Grid>

            <Grid item sm={4} xs={12} className={classes.social}>
              <Link
                className={classes.cardAction}
                href="https://fb.me/moksha.cx"
                target="_blank"
                rel="noopener">
                <Box className={classes.facebookBox}>
                  <img alt="Logo" src="/images/facebook-icon.png" width="20" />
                </Box>
              </Link>
              <Link
                className={classes.cardAction}
                href="https://www.instagram.com/moksha.cx"
                target="_blank"
                rel="noopener">
                <Box className={classes.instagramBox}>
                  <img alt="Logo" src="/images/instagram-icon.png" width="20" />
                </Box>
              </Link>
            </Grid>
            <Grid item sm={4} xs={12} className={classes.address}>
              <Typography variant="subtitle2">
                Rua Henrique Monteiro 90 <br />
                1&deg; andar <br />
                05423-020 <br />
                São Paulo SP Brasil
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12} className={classes.contact}>
              <Typography variant="subtitle2" color="primary">
                contato@moksha.cx
              </Typography>
              <Typography variant="subtitle2">
                ® 2021 Moksha Inc <br />
                Todos os direitos reservados
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Container>
    </div>
  );
};

export default FooterBar;
