/* eslint-disable no-undef */
import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
    baseURL: process.env.REACT_APP_MOKSHA_API_BASEURL
});

api.interceptors.request.use(
    async config => handlerRequest(config),
    async error => handlerError(error)
);

api.interceptors.response.use(
    async response => response,
    async error => handlerError(error)
);

const handlerRequest = config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
};

const handlerError = error => {
    if (error.response.status === 401) {
        //window.location = '/errors/error-401';
    }

    return Promise.reject(error);
};

export default api;
