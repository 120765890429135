import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

let theme = createMuiTheme({
  palette,
  typography,
  overrides
});

export default theme;
